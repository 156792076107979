import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { ToastMessage, ToastType } from "../common/ToastMessage";
import { AppContext } from "../context/AppContextProvider";
import { RouteContext } from "../context/RouteContextProvider";
import { ProductCard } from "./ProductCard/ProductCard";
import { ProductQuickView } from "./ProductQuickView";
import { CustomSlider } from "../common/CustomSlider";

const SELECTED_PRODUCT_LIMIT = 10;

export const ProductPage = () => {
  const { nextPage } = useContext(RouteContext);
  const { setScrollable } = useContext(AppContext);
  const { selectedProductIds, setSelectedProductIds } = useContext(AppContext);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstTimeOnPage, setFirstTimeOnPage] = useState(true);
  const [errorMessages, setErrorMessages] = useState<string>("");
  const [selectedTheme, setSelectedTheme] = useState<number | null>(null);
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [offset, setOffset] = useState(0);
  const [highestPrice, setHighestPrice] = useState(0);

  const { budget, setBudget } = useContext(AppContext);
  const [allProducts, setAllProducts] = useState<any[]>([]);

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_GIFTING_URL}/api/gifting/product/themes`,
        );
        const themes = response.data.data.themes;
        setThemes(themes);
        setIsLoading(false);

        // auto select first theme on load
        if (themes.length > 0) {
          const firstThemeId = themes[0].id;
          setSelectedTheme(firstThemeId);
          fetchProducts(firstThemeId, 0);
        }
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };

    fetchThemes();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
      )
        return;
      //check if fetched products are equal to total products
      if (filteredProducts.length < totalProducts) {
        fetchMoreProducts();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [filteredProducts, totalProducts]);

  useEffect(() => {}, [budget]);

  const handleBudgetChange = (value: number | null) => {
    setBudget(value);
    console.log(value, "v");
    if (value === null) {
      if (selectedTheme !== null) {
        fetchProducts(selectedTheme, 0);
      }
    } else {
      setFilteredProducts(
        allProducts.filter((product) => product.listPrice <= value),
      );
    }
  };

  const fetchProducts = async (
    themeId: number,
    offset: number,
    budget: number | null = null,
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_GIFTING_URL}/api/gifting/product/themes/${themeId}/products`,
        {
          params: {
            limit: SELECTED_PRODUCT_LIMIT,
            offset: offset,
            budget: budget,
          },
        },
      );
      console.log(response.data.data);
      setHighestPrice(
        (prevHighestPrice) =>
          response.data.data.highestPrice || prevHighestPrice,
      );
      if (offset === 0) {
        setAllProducts(response.data.data.products);
        setFilteredProducts(response.data.data.products);
      } else {
        setAllProducts((prevProducts) => [
          ...prevProducts,
          ...response.data.data.products,
        ]);
        setFilteredProducts((prevProducts) => [
          ...prevProducts,
          ...response.data.data.products,
        ]);
      }
      setTotalProducts(response.data.data.totalCount);
      setOffset(offset + SELECTED_PRODUCT_LIMIT);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchMoreProducts = async () => {
    if (selectedTheme !== null) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_GIFTING_URL}/api/gifting/product/themes/${selectedTheme}/products`,
          {
            params: {
              limit: SELECTED_PRODUCT_LIMIT,
              offset: offset,
              budget: budget,
            },
          },
        );
        setFilteredProducts((prevProducts) => [
          ...prevProducts,
          ...response.data.data.products,
        ]);
        setAllProducts((prevProducts) => [
          ...prevProducts,
          ...response.data.data.products,
        ]);

        setOffset(offset + SELECTED_PRODUCT_LIMIT);
      } catch (error) {
        console.error("Error fetching more products:", error);
      }
    }
  };

  const toggleSelected = (productId: number) => {
    if (selectedProductIds.includes(productId)) {
      setSelectedProductIds((prevProductIds) =>
        prevProductIds.filter((prevProductId) => prevProductId !== productId),
      );
      return;
    }
    setSelectedProductIds((prev) => [...prev, productId]);
  };

  const quickViewProduct = (
    event: React.MouseEvent<HTMLButtonElement>,
    productId: number,
  ) => {
    event.stopPropagation();
    const product = filteredProducts.find(
      (product) => product.id === productId,
    );
    if (product) {
      setSelectedProduct(product);
      setShowModal(true);
    }
  };

  const updateCart = (productId: number) => {
    setFirstTimeOnPage(false);
    if (selectedProductIds.includes(productId)) {
      setSelectedProductIds((selectedProductIds) =>
        selectedProductIds.filter((id) => id !== productId),
      );
      return;
    }
    if (selectedProductIds.length >= SELECTED_PRODUCT_LIMIT) {
      setErrorMessages("Unable to select more than 8 products");
      window.scrollTo(0, 0);
      return;
    }
    setSelectedProductIds((selectedProductIds) => [
      ...selectedProductIds,
      productId,
    ]);
  };

  const handleThemeClick = async (themeId: number) => {
    setSelectedTheme(themeId);
    setCurrentPage(1);
    setFilteredProducts([]);
    setOffset(0);
    setBudget(null);
    await fetchProducts(themeId, 0);
  };
  console.log(themes, "themes");

  return (
    <>
      {showModal && selectedProduct && (
        <div>
          <ProductQuickView
            product={selectedProduct}
            closeModal={() => setShowModal(false)}
          />
        </div>
      )}
      <div className="bg-orange-400 w-full py-8">
        <div className="flex flex-col items-center">
          <div className="text-2xl font-bold text-white">
            Here are some gift options
          </div>
        </div>
      </div>
      <div className="bg-white rounded-2xl mx-5 md:mx-40 py-10">
        {errorMessages && !firstTimeOnPage && (
          <ToastMessage
            messages={[errorMessages]}
            toastType={ToastType.ERROR}
          />
        )}
        <div className="mb-1">
          <div className="flex flex-wrap gap-4">
            {selectedProductIds.map((productId) => {
              const product = filteredProducts.find(
                (product) => product.id === productId,
              );
              return product ? (
                <div key={product.id} className="relative w-20 h-20">
                  <img
                    src={product.image_1}
                    alt={product.name}
                    className="w-full h-full object-cover rounded"
                  />
                  <button
                    className="absolute -top-2 -right-2 bg-orange-500 text-white rounded-full w-5 h-5 flex items-center justify-center z-30 p-2"
                    onClick={() => toggleSelected(productId)}
                  >
                    x
                  </button>
                </div>
              ) : null;
            })}
          </div>
          <div className="mt-2 text-right text-sm text-gray-600">
            {selectedProductIds.length} gifts added
          </div>
        </div>
        <div className="flex flex-wrap ">
          <div className="text-start md:w-2/12 p-1">
            <label className="block text-gray-700 text-lg md:text-xl font-bold mb-2">
              Themes
            </label>
            <ul>
              {themes.map((theme) => (
                <li key={theme.id} className="mb-2 text-left">
                  <input
                    type="radio"
                    id={`theme-${theme.id}`}
                    name="theme"
                    value={theme.id}
                    checked={selectedTheme === theme.id}
                    onChange={() => handleThemeClick(theme.id)}
                    className="hidden peer"
                  />
                  <label
                    htmlFor={`theme-${theme.id}`}
                    className={`cursor-pointer inline-block relative before:content-[''] before:absolute before:top-1/2 before:left-0 before:-translate-y-1/2 before:w-4 before:h-4 before:border-2 before:border-gray-300 before:rounded-full before:bg-white peer-checked:before:border-orange-500 peer-checked:before:bg-orange-500 peer-focus:before:ring peer-focus:before:ring-orange-300 peer-focus:before:ring-offset-2 pl-8 ${
                      selectedTheme === theme.id
                        ? "text-orange-500 font-bold"
                        : "text-gray-700"
                    } hover:text-orange-500`}
                  >
                    {theme.name} {theme.products?.length}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:hidden">
            <hr className="my-4" />
          </div>
          <div className="w-full md:w-5/12 p-1">
            <label className="block text-gray-700 text-md md:text-xl font-bold mb-2">
              Gift budget (up to)
            </label>
            <CustomSlider
              value={budget}
              onChange={handleBudgetChange}
              max={highestPrice + 10}
            />
          </div>
        </div>
        <div className="grid grid-cols-2  md:grid-cols-4 justify-start">
          {filteredProducts.map((product) => (
            <ProductCard
              updateCart={() => updateCart(product.id)}
              isSelected={selectedProductIds.includes(product.id)}
              key={product.id}
              product={product}
              displayQuickViewModal={(e, productId) =>
                quickViewProduct(e, productId)
              }
            />
          ))}
        </div>
      </div>
      {!isLoading && (
        <div className="sticky bottom-0 z-20 md:justify-end my-4 sm:mb-16 border-t-2 border-orange-500">
          <div className="bg-white p-4 w-full flex gap-4 justify-end">
            <div>
              <button
                type="button"
                className="focus:outline-none text-white bg-orange-500 hover:bg-orange-600 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg py-2.5 px-5 md:px-10 w-full"
                onClick={nextPage}
                disabled={selectedProductIds.length < 1}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
