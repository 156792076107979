import { useContext, useEffect, useState } from "react";
import { SignInModal } from "../GiftingLinkPage/SignInModal";
import { AppContext } from "../context/AppContextProvider";
import { RouteContext } from "../context/RouteContextProvider";

const personalisedMessageIcon = "/images/personalised-message-icon.svg";
const MAX_CHARACTER_LENGTH = 200;

export const PersonalizePage = () => {
  const { nextPage } = useContext(RouteContext);
  const [link, setLink] = useState("");
  const [isSignedIn, setIsSignedIn] = useState(false);
  const {
    senderName,
    setSenderName,
    recipientName,
    setRecipientName,
    message,
    setMessage,
    greeting, // Use 'greeting' state for "Dear"
    setGreeting, // Update 'greeting' state
    signOff, // Use 'signOff' state for "Regards"
    setSignOff, // Update 'signOff' state
    selectedProductIds,
  } = useContext(AppContext);

useEffect(() => {
    const productIdString = selectedProductIds.join(",");
    const url = new URL(
      `${process.env.REACT_APP_FRONTEND_URL}/gift-selection` || "",
    );
    url.searchParams.set("productIds", productIdString);
    url.searchParams.set("category", "Rewards");
    url.searchParams.set("message", message);
    url.searchParams.set("messageFromName", senderName);
    url.searchParams.set("recipientName", recipientName);
    url.searchParams.set("callback", process.env.REACT_APP_SENDGIFT_URL || "");
    url.searchParams.set("recipientMessageTitle", greeting);
    url.searchParams.set("footerGreeting", signOff);
    setLink(url.toString());
}, [selectedProductIds, message, senderName, recipientName, greeting, signOff]);

  const handleContinue = () => {
    if (!recipientName || !senderName) {
      return;
    }
    setIsSignedIn(true);
  };

  return (
    <>
      {isSignedIn && (
        <SignInModal
          signedIn={() => {
            setIsSignedIn(false);
            nextPage();
          }}
          visibility={() => setIsSignedIn(false)}
        />
      )}
      <div className="w-full md:w-1/2 mx-auto p-4">
        <div className="flex col gap-2">
          <img src={personalisedMessageIcon} alt="Personalised Message Icon" />
          <span className="text-neutralPalette-900 text-2xl py-5">
            Personalize your greeting card
          </span>
        </div>
        <div className="flex flex-col gap-5 bg-primaryPalette-200 rounded-2xl p-6">
          <div className="flex ">
            <input
              className="rounded-3xl w-full md:w-1/4 mr-2 px-4 py-2 border border-neutralPalette-500"
              placeholder="Dear"
              defaultValue={greeting}
              onChange={(e) => setGreeting(e.target.value)}
            />
            <input
              className="rounded-3xl w-full md:w-1/3 px-4 py-2 border border-neutralPalette-500"
              placeholder="Recipient's name"
              defaultValue={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
            />
          </div>
          <textarea
            className="rounded-3xl w-full px-4 py-2 border border-neutralPalette-500 resize-none"
            defaultValue={message}
            placeholder="Write a heartfelt message..."
            rows={5}
            maxLength={MAX_CHARACTER_LENGTH}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="flex justify-between">
            <input
              className="rounded-3xl w-1/2 md:w-1/3 px-4 py-2 border border-neutralPalette-500"
              placeholder="Regards"
              defaultValue={signOff}
              onChange={(e) => setSignOff(e.target.value)}
            />
          </div>
          <div className="flex justify-between">
            <input
              className="rounded-3xl w-1/2 md:w-1/3 px-4 py-2 border border-neutralPalette-500"
              placeholder="Sender name"
              defaultValue={senderName}
              onChange={(e) => setSenderName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <a
            href={link}
            className="inline-block border rounded-full border-primaryPalette-600 py-3 px-5 my-5 w-2/5 md:1/5 text-primaryPalette-600 whitespace-nowrap"
          >
            Preview Link
          </a>
          <button
            onClick={handleContinue}
            className="rounded-full border border-primaryPalette-600 bg-primaryPalette-600 py-3 px-5 my-5 text-neutral-100 w-2/5 md:1/5"
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};
//  onClick={handleContinue}
