import React, { useState, ChangeEvent, useEffect } from 'react';

type PropTypes = {
  value: number | null;
  onChange: (value: number) => void;
  min?: number;
  max: number;
  step?: number;
};

export const CustomSlider = ({
  value,
  onChange,
  min = 0,
  max,
  step = 10,
}: PropTypes) => {
  const [sliderValue, setSliderValue] = useState(value ?? min);

  useEffect(() => {
    setSliderValue(value ?? min);
  }, [value, min]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    setSliderValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="w-full flex flex-col items-center py-4">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        onChange={handleChange}
        className="w-full"
        style={{
          appearance: 'none',
          width: '100%',
          height: '10px',
          background: '#ddd',
          outline: 'none',
          opacity: 0.7,
          transition: 'opacity 0.2s',
        }}
      />
      <div className="mt-2 text-orange-500 text-lg">${sliderValue}</div>
      <style>
        {`
          input[type='range']::-webkit-slider-thumb {
            appearance: none;
            width: 20px;
            height: 20px;
            background: orange;
            cursor: pointer;
            border-radius: 50%;
          }
          input[type='range']::-moz-range-thumb {
            width: 20px;
            height: 20px;
            background: orange;
            cursor: pointer;
            border-radius: 50%;
          }
        `}
      </style>
    </div>
  );
};
