import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { AppContextProvider } from "./context/AppContextProvider";
import route from "./route/Routes";

function App() {
  return (
    <AppContextProvider>
      <div className="App font-leagueSpartan">
        <BrowserRouter>
          <Routes>
            {route.map((r) => (
              <Route key={r.path} path={r.path} element={<r.component />} />
            ))}
          </Routes>
          {/* <RouteContextProvider /> */}
        </BrowserRouter>
      </div>
    </AppContextProvider>
  );
}

export default App;
