import FailCheckoutPage from "../GiftingLinkPage/FailCheckoutPage";
import { SendGiftPage } from "../SendGift/SendGiftPage";
import { RouteContextProvider } from "../context/RouteContextProvider";

export type pathType = "/failed-checkout" | "/success-checkout" | "/";

const routes: {
  name: string;
  component: () => JSX.Element;
  path: pathType;
}[] = [
  {
    name: "Failed Checkout Page",
    path: "/failed-checkout",
    component: FailCheckoutPage,
  },
  {
    name: "Success Checkout Page",
    path: "/success-checkout",
    component: SendGiftPage,
  },
  {
    name: "Default",
    path: "/",
    component: RouteContextProvider,
  },
];

export default routes;
