import axios from "axios";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useLocation } from "react-router-dom";
import images from "../constants/images";
import CopyAnimation from "../constants/lottie/CopySuccess.json";
import MainAnimation from "../constants/lottie/Success.json";

export const SendGiftPage = () => {
  const location = useLocation();
  const [copied, setCopied] = useState(false);
  const [url, setUrl] = useState("");
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const recipientUrl = queryParams.get("recipientUrl");
    const url = `${process.env.REACT_APP_FRONTEND_URL}/gift-selection?recid=${recipientUrl}`;
    if (recipientUrl && recipientUrl.length) activateCampaign(recipientUrl);
    setUrl(url);
  }, [location.search]);

  const activateCampaign = (recipientUrl: string) => {
    axios
      .post(
        `${process.env.REACT_APP_CORP_URL}/api/corp/activateSendgiftCampaign`,
        { recId: recipientUrl },
      )
      .then((response) => {
        localStorage.clear();
      })
      .catch((error) => {
        setHasError(true);
      });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
  };

  if (hasError) {
    return (
      <div className="text-red-500">
        There was an issue encountered with creating your gift, please contact
        Smilie's support team at 8260 1710 or drop an email at{" "}
        <a href="mailto:gifting@smilie.io">gifting@smilie.io</a>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-center items-center h-screen m-auto">
        <div className="lg:w-1/3 sm:w-3/5 grid gap-y-6">
          <div className="grid grid-cols-1 border-[1px] rounded-xl border-slate-300 p-12 shadow-md">
            <div className="col-span-1 text-orange-500 text-leagueSpartan-600 font-bold">
              Your gifting link has been activated!
            </div>
            <div className="my-6">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: MainAnimation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={150}
                width={150}
              ></Lottie>
            </div>
            <p>
              Share this link to your recipient and they will receive their
              chosen gift in no time!
            </p>
            <div className="grid grid-cols-5 mt-4 gap-2">
              <div className="border-[1px] border-slate-300 rounded-2xl p-4 col-span-4 text-left ps-4">
                {url}
              </div>
              <div className="w-6 m-auto">
                <button onClick={copyToClipboard}>
                  {copied ? (
                    <Lottie
                      options={{
                        loop: false,
                        autoplay: true,
                        animationData: CopyAnimation,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid meet",
                        },
                      }}
                    />
                  ) : (
                    <img src={images.Copy} alt="" />
                  )}
                </button>
              </div>
            </div>
            <div className="flex justify-center">
              <a
                href={
                  process.env.REACT_APP_SENDGIFT_URL ||
                  "https://sendgiftdev.smilie.io/"
                }
                className="rounded-full border border-primaryPalette-600 bg-primaryPalette-600 py-3 px-5 mt-5 text-neutral-100 w-7/10 md:1/5 whitespace-nowrap"
              >
                Start gifting with Smilie
              </a>
            </div>
          </div>
        </div>

        <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
      </div>
    </>
  );
};
