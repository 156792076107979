type PropTypes = {
  messages: string[];
  className?: string;
  toastType: ToastType;
};

export enum ToastType {
  ERROR,
  SUCCESS,
  INFO,
}
export const ToastMessage = ({ messages, className, toastType }: PropTypes) => {
  let messageToBeDisplayed = messages.map((message) => {
    return <ul>{message}</ul>;
  });
  if (toastType === ToastType.ERROR) {
    return (
      <div
        className={`animate-bounce bg-errorPalette-200 text-errorPalette-500 px-4 py-2 m-2 ${className}`}
      >
        <span className="inline-block align-middle">
          {messageToBeDisplayed}
        </span>
      </div>
    );
  }
  if (toastType === ToastType.SUCCESS) {
    return (
      <div
        className={`animate-bounce bg-successPalette-100 text-successPalette-400 px-4 py-2 m-2 ${className}`}
      >
        <span className="inline-block align-middle">
          {messageToBeDisplayed}
        </span>
      </div>
    );
  }

  return (
    <div
      className={`animate-bounce bg-infoPalette-100 text-infoPalette-500 px-4 py-2 m-2 ${className}`}
    >
      <span className="inline-block align-middle">{messageToBeDisplayed}</span>
    </div>
  );
};
