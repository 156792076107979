import { useEffect, useState } from "react";

type OtpModalProps = {
  visible: boolean;
  resendOtp: () => void;
  onCancel: () => void;
  onSubmit: (otp: string) => void;
  errorMessage?: string;
};

export const OTPVerification = ({
  visible,
  resendOtp,
  onCancel,
  onSubmit,
  errorMessage,
}: OtpModalProps) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [resendTimer, setResendTimer] = useState(30);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [resendTimer]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };

  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === "Backspace" && otp[index] === "") {
      const previousElement = document.getElementById(`otp-${index - 1}`);
      if (previousElement) {
        previousElement.focus();
      }
    } else if (e.key !== "Backspace") {
      const nextElement = document.getElementById(`otp-${index + 1}`);
      if (nextElement) {
        nextElement.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("text").split("").slice(0, 4);
    if (pasteData.every((char) => /^\d$/.test(char))) {
      setOtp(pasteData);
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg w-[410px] p-6 flex flex-col items-center shadow-lg">
      <h2 className="mb-4 text-2xl font-semibold">One-Time Password</h2>
      <p className="text-gray-500 mb-4">
        Enter the OTP sent to your mobile number by Smilie
      </p>
      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
      <div className="flex justify-between mb-4 w-full max-w-xs">
        {otp.map((digit, index) => (
          <input
            key={index}
            id={`otp-${index}`}
            type="text"
            value={digit}
            onChange={(e) => handleChange(e, index)}
            onKeyUp={(e) => handleKeyUp(e, index)}
            onPaste={handlePaste}
            maxLength={1}
            className="w-12 h-12 text-center text-2xl border rounded-md"
          />
        ))}
      </div>
      <button
        onClick={resendOtp}
        className={`mb-4 ${resendTimer > 0 ? "text-gray-500" : "text-orange-500"}`}
        disabled={resendTimer > 0}
      >
        {resendTimer > 0 ? `Resend OTP in ${resendTimer}s` : "Resend OTP"}
      </button>
      <button
        onClick={() => onSubmit(otp.join(""))}
        className="bg-orange-500 rounded-2xl text-white px-4 py-2 mb-2 w-full max-w-xs"
      >
        Submit
      </button>
      <button onClick={onCancel} className="text-gray-500 w-full max-w-xs">
        Cancel
      </button>
    </div>
  );
};
