import { createContext, useState } from "react";
import { usePersistantState } from "../hooks/usePersistantState";

const INITIAL_BUDGET = { lowerBound: 20, upperBound: 30 };
type TAppContext = {
  // Question Page
  prompt?: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  budget: number | null;
  setBudget: React.Dispatch<React.SetStateAction<number | null>>;
  recipientName: string;
  setRecipientName: React.Dispatch<React.SetStateAction<string>>;
  selectedProductIds: number[];
  setSelectedProductIds: React.Dispatch<React.SetStateAction<number[]>>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  recipientUrl: string;
  setRecipientUrl: React.Dispatch<React.SetStateAction<string>>;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  greeting: string;
  setGreeting: React.Dispatch<React.SetStateAction<string>>;
  signOff: string;
  setSignOff: React.Dispatch<React.SetStateAction<string>>;
  senderName: string;
  setSenderName: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  excludeProductIds: Set<number>;
  setExcludeProductIds: React.Dispatch<React.SetStateAction<Set<number>>>;
  totalProductCount: number;
  setTotalProductCount: React.Dispatch<React.SetStateAction<number>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;

  //scrolling
  scrollable: boolean;
  setScrollable: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppContext = createContext<TAppContext>({
  budget: 0,
  setBudget: () => {},
  recipientName: "",
  selectedProductIds: [],
  setRecipientName: () => {},
  setPrompt: () => {},
  setSelectedProductIds: () => {},
  message: "",
  setMessage: () => {},
  recipientUrl: "",
  setRecipientUrl: () => {},
  token: "",
  setToken: () => {},
  greeting: "",
  setGreeting: () => {},
  signOff: "",
  setSignOff: () => {},
  senderName: "",
  setSenderName: () => {},
  phoneNumber: "",
  setPhoneNumber: () => {},
  excludeProductIds: new Set(),
  setExcludeProductIds: () => {},
  totalProductCount: 0,
  setTotalProductCount: () => {},
  email: "",
  setEmail: () => {},
  scrollable: true,
  setScrollable: () => {},
});

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

export const AppContextProvider = ({ children }: Props) => {
  const [recipientName, setRecipientName] = usePersistantState<string>(
    "recipientName",
    "",
  );
  const [budget, setBudget] = usePersistantState<number | null>("budget", 0);
  const [prompt, setPrompt] = usePersistantState<string>("prompt", "");
  const [selectedProductIds, setSelectedProductIds] = usePersistantState<
    number[]
  >("selectedProductIds", []);
  const [message, setMessage] = usePersistantState<string>("message", "");
  const [recipientUrl, setRecipientUrl] = usePersistantState<string>(
    "recipientUrl",
    "",
  );
  const [token, setToken] = usePersistantState<string>("token", "");
  const [greeting, setGreeting] = usePersistantState<string>("greeting", "");
  const [signOff, setSignOff] = usePersistantState<string>("signOff", "");
  const [senderName, setSenderName] = usePersistantState<string>(
    "senderName",
    "",
  );
  const [phoneNumber, setPhoneNumber] = usePersistantState<string>(
    "phoneNumber",
    "",
  );
  const [excludeProductIds, setExcludeProductIds] = usePersistantState<
    Set<number>
  >("excludeProductIds", new Set());

  const [totalProductCount, setTotalProductCount] = usePersistantState<number>(
    "totalProductCount",
    0,
  );

  const [email, setEmail] = usePersistantState<string>("email", "");
  const [scrollable, setScrollable] = useState(true);
  return (
    <AppContext.Provider
      value={{
        recipientName,
        setRecipientName,
        budget,
        setBudget,
        setPrompt,
        prompt,
        selectedProductIds,
        setSelectedProductIds,
        message,
        setMessage,
        recipientUrl,
        setRecipientUrl,
        token,
        setToken,
        greeting,
        setGreeting,
        signOff,
        setSignOff,
        senderName,
        setSenderName,
        phoneNumber,
        setPhoneNumber,
        excludeProductIds,
        setExcludeProductIds,
        totalProductCount,
        setTotalProductCount,
        email,
        setEmail,
        scrollable,
        setScrollable,
      }}
    >
      <div className={`${!scrollable && "max-h-screen overflow-hidden"}`}>
        {children}
      </div>
    </AppContext.Provider>
  );
};
