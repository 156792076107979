import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const usePersistantState = <T>(
  key: string,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>] => {
  const rawData = localStorage.getItem(key);

  const [state, setState] = useState<T>(() => {
    if (rawData === null) return defaultValue;

    const parsed = JSON.parse(rawData);

    //force budget to 0 if previously saved as object 
    if (
      key === "budget" &&
      parsed !== null &&
      parsed !== undefined &&
      typeof parsed === "object"
    ) {
      return 0 as T;
    }

    if (defaultValue instanceof Set) {
      return new Set(parsed) as T;
    }
    return parsed as T;
  });

  useEffect(() => {
    let serializedValue = "";
    if (state instanceof Set) {
      serializedValue = JSON.stringify(Array.from(state));
    } else {
      serializedValue = JSON.stringify(state);
    }
    localStorage.setItem(key, serializedValue);
  }, [key, state]);

  return [state, setState];
};
