export const imagePath = "/images/";
const images = {
  giftGif: imagePath + "gift.gif",
  Close: imagePath + "Close.svg",
  Cross: imagePath + "Cross.svg",
  CircleCheck: imagePath + "CircleCheck.svg",
  Swap: imagePath + "Swap.svg",
  giftIcon: imagePath + "giftBoxIcon.svg",
  highlightLeft: imagePath + "highlightleft.svg",
  highlightRight: imagePath + "highlightright.svg",
  smilieSteps1: imagePath + "smilieSteps1.svg",
  smilieSteps2: imagePath + "smilieSteps2.svg",
  smilieSteps3: imagePath + "smilieSteps3.svg",
  bookmarkTag1: imagePath + "bookmarktag1.svg",
  backgroundRibbon: imagePath + "BackgroundRibbon.png",
  amIAddicted: imagePath + "amiaddictedpng.png",
  natureland: imagePath + "naturelandpng.png",
  oto: imagePath + "oto.png",
  brownBrothers: imagePath + "brownbrotherspng.png",
  hegen: imagePath + "hegen.png",
  byndArtisan: imagePath + "byndartisan.png",
  backgroundConfetti: imagePath + "backgroundConfetti.svg",
  forColleague: imagePath + "forColleague.svg",
  forNewborn: imagePath + "forNewborn.svg",
  forClient: imagePath + "forClient.svg",
  Success: imagePath + "Success.json",
  Back: imagePath + "Back.svg",
  Copy: imagePath + "Copy.svg",
  eyeIcon: imagePath + "eyeIcon.svg",
  EyeOrange: imagePath + "EyeOrange.svg",
  Logo: imagePath + "Logo.svg",
  RightArrow: imagePath + "RightArrow.svg",
  BackgroundRibbon: imagePath + "BackgroundRibbon.png",
  testImages: [
    imagePath + "testImages/image_1.png",
    imagePath + "testImages/image_2.png",
    imagePath + "testImages/image_3.png",
    imagePath + "testImages/image_4.png",
  ],
  testGiftingPartners: [
    imagePath + "testGiftingPartners/partner_1.png",
    imagePath + "testGiftingPartners/partner_2.png",
    imagePath + "testGiftingPartners/partner_4.png",
    imagePath + "testGiftingPartners/partner_7.png",
    imagePath + "testGiftingPartners/partner_8.png",
  ],
  defineStep: imagePath + "DefineStep.svg",
  sendLinkStep: imagePath + "SendLinkStep.svg",
  searchStep: imagePath + "SearchStep.svg",
  giftStep: imagePath + "GiftsStep.svg",
  DropDownArrow: imagePath + "DropDownArrow.svg",
  MouseClick: imagePath + "MouseClick.svg",
  Cart: imagePath + "Cart.svg",
  advantagesBg: imagePath + "advantages/AdvantagesSectionBg.svg",
  giftSelections: imagePath + "advantages/GiftSelections.svg",
  smilesDelivered: imagePath + "advantages/SmilesDelivered.svg",
  spendings: imagePath + "advantages/Spendings.svg",
};
export default images;