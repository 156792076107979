import images from "../../constants/images";

export const ProductCard = ({
  product,
  isSelected,
  displayQuickViewModal,
  updateCart,
}: {
  product: any;
  updateCart: () => void;
  isSelected: boolean;
  displayQuickViewModal: (
    e: React.MouseEvent<HTMLButtonElement>,
    productId: number,
  ) => void;
}) => {
  return (
    <div
      onClick={updateCart}
      key={product.id}
      className={`relative group p-2 md:p-4 md:text-xl grow cursor-pointer `}
    >
      <img
        src={images.CircleCheck}
        alt="selected product"
        className={`fill-orange-500 absolute overflow-visible h-7 w-12 md:h-8 md:w-8 -right-5 -top-2 md:-right-4  md:-top-1 ${isSelected ? "visible" : "invisible"}`}
      />
      <div
        className={`${isSelected && "ring-2 ring-orange-500"} flex flex-col items-center relative shadow rounded-xl h-full overflow-clip`}
      >
        <div className="w-full rounded-t-xl overflow-hidden">
          <img
            src={product.image_1}
            className="w-full aspect-square object-cover group-hover:scale-125 transition-all duration-500"
            alt={product.name}
            draggable={false}
          />
        </div>
        <div className="border-t-2 border-orange-500 w-full h-2/5">
          <div className="w-full pt-2 px-3 text-left h-full">
            <div className="grid grid-rows-3 ">
              <div className="text-left w-full text-xs md:text-sm text-neutral-800 row-span-1">
                {product.brand}
              </div>
              <div className="font-medium text-sm md:text-base line-clamp-2 text-start row-span-2 text-ellipsis ">
                {product.name}
              </div>
            </div>

            <div>
              <button
                type="button"
                onClick={(e) => displayQuickViewModal(e, product.id)}
                className="inline-flex items-center text-xs md:text-sm text-center text-orange-500"
              >
                Quick View
                <img
                  src={images.EyeOrange}
                  className="inline-flex items-center justify-center w-3 h-3 md:w-4 md:h-4 ml-1 rounded-full fill-white"
                  alt="Quick view product"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
