import Hotjar from "@hotjar/browser";
import { createContext, useEffect, useState } from "react";
import { FullPageSection } from "../FullPageSection/FullPageSection";
import { BalancePage } from "../GiftingLinkPage/BalancePage";
import { PersonalizePage } from "../PersonalizePage/PersonalizePage";
import { ProductPage } from "../ProductPage/ProductPage";
import { QuestionPage } from "../QuestionPage/QuestionPage";
import { SendGiftPage } from "../SendGift/SendGiftPage";
import { usePersistantState } from "../hooks/usePersistantState";

const STAGES = [
  // <QuestionPage key="question" />,
  <ProductPage key="product" />,
  <PersonalizePage key="personalize" />,
  <BalancePage key="balance" />,
  <SendGiftPage key="sendgift" />,
];

const STAGE_NAMES = [
  // "question",
  "product",
  "personalize",
  "balance",
  "sendgift",
];

export const RouteContext = createContext({
  nextPage: () => {},
  previousPage: () => {},
});

export const RouteContextProvider = () => {
  const [stageIndex, setStageIndex] = usePersistantState<number>(
    "stageIndex",
    0,
  );
  const [activePage, setActivePage] = useState(STAGES[stageIndex]);

  useEffect(() => {
    const hotjarId = Number(process.env.REACT_APP_HOTJAR_ID) || 0;
    Hotjar.init(hotjarId, 6, { debug: true });
  }, []);

  useEffect(() => {
    setActivePage(STAGES[stageIndex]);
    localStorage.setItem("stageIndex", stageIndex.toString());
    window.scrollTo(0, 0);

    // notify hotjar of the page change
    const pageName = `/stage-${STAGE_NAMES[stageIndex]}`;
    Hotjar.stateChange(pageName);
  }, [stageIndex]);

  const nextPage = () => {
    if (stageIndex < STAGES.length - 1) {
      Hotjar.event("next-page");
      setStageIndex((prev) => prev + 1);
    }
  };

  const previousPage = () => {
    if (stageIndex > 0) {
      Hotjar.event("previous-page");
      setStageIndex((prev) => prev - 1);
    }
  };

  return (
    <RouteContext.Provider value={{ nextPage, previousPage }}>
      {stageIndex > 0 && stageIndex < STAGES.length && (
        <div className="flex justify-start sticky top-0 bg-orange-500 z-30">
          <button
            type="button"
            className="hover:underline focus:outline-none text-white font-medium rounded-lg p-2.5 text-lg"
            onClick={previousPage}
          >
            ← Back
          </button>
        </div>
      )}
      <FullPageSection>{activePage}</FullPageSection>
    </RouteContext.Provider>
  );
};
