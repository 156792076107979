import axios from "axios";
import { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { OTPVerification } from "../common/OTPVerification";
import images from "../constants/images";
import { AppContext } from "../context/AppContextProvider";
import "./PhoneInput.css";

type Props = {
  signedIn: (isSignedIn: boolean) => void;
  visibility: (setVisibility: boolean) => void;
};
export const SignInModal = ({ signedIn, visibility }: Props) => {
  const { phoneNumber, setPhoneNumber, email, setEmail, setToken, token } =
    useContext(AppContext);

  const [number, setNumber] = useState<{
    value: string;
    format: string;
  }>({ value: phoneNumber || "", format: "" });

  const [errorMessage, setErrorMessage] = useState("");
  const [otpErrorMessage, setOtpErrorMessage] = useState("");

  const [otpModalVisible, setOtpModalVisible] = useState(false);
  const [isOtpVerified, setOtpVerified] = useState(false);
  const [doesUserHaveEmail, setDoesUserHaveEmail] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const [isLoadingOTP, setIsLoadingOTP] = useState(false);

  const handleModalVisibility = () => {
    visibility(false);
  };

  const updateUserEmailAddress = (emailAddress: string) => {
    return axios
      .post(
        process.env.REACT_APP_AUTH_URL + "/api/auth/access/updateEmail",
        { email: emailAddress },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then(({ data }) => data.data.email);
  };

  const requestOtp = () => {
    if (!phoneNumber) return setErrorMessage("Enter a Phone Number");
    setIsLoadingOTP(true);
    axios
      .post(
        process.env.REACT_APP_AUTH_URL + "/api/auth/access/getPhoneNumberOtp",
        { phoneNumber },
      )
      .then(() => {
        setIsLoadingOTP(false);
        setOtpModalVisible(true);
        setOtpErrorMessage("");
      })
      .catch(() => setErrorMessage("Invalid Phone number"));
  };

  const resendOtp = () => {
    if (resendTimer === 0) {
      setResendTimer(30);
    }
    setOtpErrorMessage("");
  };

  const submitOtp = (otp: string) => {
    axios
      .post(
        process.env.REACT_APP_AUTH_URL +
          "/api/auth/access/login/phoneNumberOtp",
        { phoneNumber, otp },
      )
      .then(({ data }) => {
        setOtpVerified(true);
        setIsLoadingOTP(false);
        setOtpModalVisible(false);

        const { email, tokens } = data.data;
        setToken(tokens.access_token);
        setEmail(email);
        setDoesUserHaveEmail(!!email);
      })
      .catch(() => setOtpErrorMessage("Invalid OTP"));
  };

  const handleContinue = async () => {
    const errorMessage = [];
    if (!isOtpVerified || !token) {
      errorMessage.push("OTP is not verified");
    }

    if (!phoneNumber) {
      // TODO: Validate phone number
      errorMessage.push("Phone Number is not verified");
    }

    if (!email) {
      // TODO: Validate email
      errorMessage.push("Email is required");
    }

    if (!doesUserHaveEmail) {
      await updateUserEmailAddress(email)
        .then((email) => {
          setEmail(email);
          setDoesUserHaveEmail(true);
        })
        .catch(() =>
          errorMessage.push("Email address already in use or not allowed"),
        );
    }

    if (errorMessage.length > 0) {
      setErrorMessage(errorMessage.join("\n"));
    } else {
      signedIn(true);
    }
  };

  return (
    <div className="bg-black bg-opacity-60 flex items-center justify-center overflow-scroll fixed top-0 left-0 w-full h-full">
      <div className="bg-white rounded-lg w-[520px] h-[610px] p-14">
        {/* HEADER */}
        <div className="font-leagueSpartan font-medium text-2xl text-center text-neutralPalette-900">
          <span>Get updates with your details</span>
          <div className="relative">
            <button
              className="absolute -right-5 -top-10"
              onClick={handleModalVisibility}
            >
              <img src={images.Close} alt="close" />
            </button>
          </div>
        </div>

        <div className="flex flex-col font-leagueSpartan gap-5 content-center justify-center py-5 h-full">
          <div className="flex flex-col">
            <label
              className="text-left font-medium text-neutralPalette-900"
              htmlFor="phoneNumber"
            >
              Your phone number
            </label>

            <PhoneInput
              containerClass={`px-2 border rounded-full ${isOtpVerified && "bg-slate-200"}`}
              inputClass="font-leagueSpartan"
              country={"sg"}
              preferredCountries={["sg"]}
              enableSearch={true}
              placeholder=""
              value={number?.value}
              inputProps={{
                name: "number",
                required: true,
              }}
              onMount={(value, data) => {
                const { format } = { format: "", ...data };
                setNumber({ value, format });
              }}
              onChange={(value, data) => {
                const { format } = { format: "", ...data };
                setNumber({ value, format });
                setPhoneNumber(value);
              }}
              disabled={isOtpVerified}
            />
          </div>

          {isLoadingOTP && (
            <div role="status">
              <svg
                aria-hidden="true"
                className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-orange"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {!isOtpVerified && !isLoadingOTP && (
            <button
              className={`w-full border rounded-[32px] px-4 py-2 text-primaryPalette-100 bg-primaryPalette-600 ${!number.value ? " bg-neutralPalette-500" : ""}`}
              disabled={!number.value || isOtpVerified || isLoadingOTP}
              onClick={requestOtp}
            >
              Send OTP
            </button>
          )}
          {isOtpVerified && !doesUserHaveEmail && (
            <div className="flex flex-col font-leagueSpartan text-base gap-2">
              <label
                className="text-left font-medium text-neutralPalette-900"
                htmlFor="email"
              >
                Your email address
              </label>
              <input
                className="w-full border border-neutralPalette-500 rounded-[21px] px-4 py-2 bg-neutralPalette-100 text-sm"
                id="email"
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  // Simple email validation
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  if (!emailRegex.test(e.target.value)) {
                    setErrorMessage("Please enter a valid email address");
                  } else {
                    setErrorMessage("");
                  }
                }}
              />
            </div>
          )}
          {errorMessage && <div className="text-red-500">{errorMessage}</div>}
          {isOtpVerified && (
            <button
              type="button"
              className="w-full cursor-pointer border rounded-full px-4 py-2 text-primaryPalette-100 bg-primaryPalette-600"
              onClick={handleContinue}
            >
              Continue
            </button>
          )}
        </div>
        {otpModalVisible && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <OTPVerification
              visible={otpModalVisible}
              resendOtp={resendOtp}
              onCancel={() => setOtpModalVisible(false)}
              onSubmit={submitOtp}
              errorMessage={otpErrorMessage}
            />
          </div>
        )}
      </div>
    </div>
  );
};
